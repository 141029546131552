import { createApp } from 'vue';
import ConsentApp from './ConsentApp.vue';
import Cookies from 'js-cookie';
import { data } from './data';
const COOKIE_SEPARATOR = '!';
const MONTH_IN_SEC = 3600 * 24 * 31;
const BASE_COOKIE_PROPS = {
    domain: data.state_cookie.domain || '.' + window.location.host.split('.').reverse().slice(0, 2).reverse().join('.'),
    expires: new Date(Date.now() + (data.state_cookie.expiration || MONTH_IN_SEC) * 1000),
    path: data.state_cookie.path || '/',
};
export function initCookieConsent() {
    const rootEl = document.getElementById('gdprc-app');
    if (rootEl) {
        try {
            const app = createApp(ConsentApp);
            const appInstance = app.mount(rootEl);
            return {
                app,
                appInstance,
            };
        }
        catch (err) {
            console.warn(`Failed to initialize GDPR Consent due to an error`, err);
        }
    }
    return { app: null, appInstance: null };
}
export function userHasMadeAChoice() {
    if (Cookies.get(data.state_cookie.key)) {
        return true;
    }
    const reqExp = parseInt(localStorage.getItem(data.reminder.key) || '0');
    return new Date(reqExp || 0) > new Date();
}
export function getPersistedConsentTypesList() {
    const acceptedIds = data ? (Cookies.get(data.state_cookie.key) || '').split(COOKIE_SEPARATOR).filter(Boolean) : [];
    return data.consent_types.filter(t => t.required ||
        acceptedIds.includes(t.id) ||
        t.controls_cookie_types_ids?.some(cid => acceptedIds.includes(cid)));
}
export function persistConsentTypesList(consentTypes) {
    let onlyRequired = consentTypes.every(ct => ct.required);
    if (onlyRequired) {
        // Remove cookie completely
        Cookies.remove(data.state_cookie.key, BASE_COOKIE_PROPS);
        const now = new Date();
        localStorage.setItem(data.reminder.key, (new Date(now.getTime() + data.reminder.expiration * 1000)).getTime()
            .toString());
    }
    else {
        // Persist only non-required consents
        for (const consentType of data.consent_types.filter(ct => !ct.required)) {
            const ids = consentType.controls_cookie_types_ids ? consentType.controls_cookie_types_ids : [consentType.id];
            const status = consentTypes.find(ct => ct.id === consentType.id) ? 'granted' : 'denied';
            window.gtag('consent', 'update', Object.fromEntries(ids.map(id => [id, status])));
        }
        const payload = consentTypes
            .filter(ct => !ct.required)
            .map(ct => ct.controls_cookie_types_ids || ct.id)
            .flat()
            .join('!');
        Cookies.set(data.state_cookie.key, payload, BASE_COOKIE_PROPS);
        localStorage.removeItem(data.reminder.key);
    }
}
