import { initCookieConsent } from './consent';
import { data } from './data';
if (data) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || function gtag() { window.dataLayer.push(arguments); };
    let { app, appInstance } = initCookieConsent();
    window.gdprc = {
        open: () => appInstance?.open(),
        close: () => appInstance?.close(),
        toggle: () => appInstance?.toggle(),
        get acceptedConsentTypes() { return appInstance?.acceptedConsentTypes(); },
    };
}
else {
    console.warn('GDPR Consent data not found');
}
