export const data = readConsentData();
function readConsentData() {
    const dataEl = document.getElementById('gdprc-data');
    if (dataEl) {
        try {
            return JSON.parse(dataEl.innerText);
        }
        catch (err) {
            console.warn(err);
        }
    }
    return null;
}
export function str(...pathFragments) {
    let o = data.strings;
    let backup = [];
    for (let f of pathFragments) {
        if (typeof o !== 'string') {
            if (typeof o[f] === 'string') {
                return o[f];
            }
            else if (o[f] && typeof o[f] === 'object') {
                o = o[f];
            }
        }
        backup.push(f);
    }
    const phrase = backup.join(' ');
    return phrase[0].toUpperCase() + phrase.slice(1);
}
